// src/components/RegisterDemo.js

import React from "react";
import {
  Button,
  Label,
  FormGroup,
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import Bg from "./design/Bg";
import Design from "./design/Design";
import logo from "../../landing/logo5.png";
import jwtDecode from "jwt-decode";

function RegisterDemo() {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const initialValues = {
    UserName: "",
    email: "",
    password: "",
    company: "",
    confirmPassword: "",
    phone: "",
    acceptTerms: false,
  };


  const validationSchema = Yup.object().shape({
    UserName: Yup.string().required("User Name is required"),
    email: Yup.string()
      .email("Email is invalid")
      .required("Email is required"),
    company: Yup.string().required("Company Name is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    acceptTerms: Yup.bool().oneOf(
      [true],
      "Accept Terms & Conditions is required"
    ),
  });

  const handleSubmit = async (fields, { setSubmitting }) => {
    const loading = message.loading("Registering...", 0);
    try {
      // Send the registration request
      const response = await fetch(
        `${process.env.REACT_APP_FOS_CLIENT_API}/admin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: fields.UserName,
            email: fields.email,
            password: fields.password,
            role: "VENDOR",
            company: fields.company,
            phone: fields.phone,
          }),
        }
      );
      console.log(response, "register response");

      // Check if the registration was successful
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Registration failed");
      }

      // If registration is successful, send the email
      const emailResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/mail/send-email?to=${fields.email}&username=${fields.UserName}&code=Q5aJVls`,
        {
          method: "GET",
        }
      );

      if (!emailResponse.ok) {
        const errorData = await emailResponse.json();
        throw new Error(
          errorData.message || "Failed to send confirmation email"
        );
      }

      // Dismiss the loading message
      loading(); // Close the loading message

      // Show success message
      message.success("Registration successful!");

      // Redirect to login page after successful registration
      navigate("/auth/login");
    } catch (error) {
      // Dismiss the loading message
      loading(); // Close the loading message

      // Show error message
      console.error("Registration failed:", error.message);
      message.error(error.message);
    } finally {
      // Stop form submission state
      setSubmitting(false);
    }
  };

  

  return (
    <div className="loginBox ">
      {contextHolder}
      <Bg />
      <Design />

      <Container
        style={{ height: "100vh", overflow: "hidden" }}
        fluid
        className="h-100"
      >
        <Row className="justify-content-center align-items-center h-100">
          <Col lg="6"></Col>
          <Col style={{ zIndex: "23" }} lg="6" className="p-0">
            <Card
              style={{
                height: "100vh",
                borderTopLeftRadius: "2rem",
                borderBottomLeftRadius: "2rem",
              }}
            >
              <div className="text-center mt-4">
                <img src={logo} style={{ width: "150px" }} alt="logo" />
              </div>
              <center>
                <h1 className="mb-0 fw-bold">Registration</h1>
              </center>
              <CardBody
                style={{ height: "100vh", overflowX: "auto" }}
                className="m-0 p-5"
              >
                <div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <FormGroup>
                          <Label htmlFor="UserName">Name</Label>
                          <Field
                            name="UserName"
                            placeholder="Enter your UserName"
                            type="text"
                            style={{ height: "40px" }}
                            className={`form-control ${
                              errors.UserName && touched.UserName
                                ? " is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="UserName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="email">Email</Label>
                          <Field
                            name="email"
                            placeholder="Enter your email"
                            style={{ height: "40px" }}
                            type="text"
                            className={`form-control${
                              errors.email && touched.email ? " is-invalid" : ""
                            }`}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="company">Company</Label>
                          <Field
                            name="company"
                            placeholder="Enter your company"
                            type="text"
                            style={{ height: "40px" }}
                            className={`form-control${
                              errors.company && touched.company
                                ? " is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="company"
                            component="div"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="phone">Phone</Label>
                          <Field
                            name="phone"
                            placeholder="Enter your phone"
                            type="text"
                            style={{ height: "40px" }}
                            className={`form-control${
                              errors.phone && touched.phone ? " is-invalid" : ""
                            }`}
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="password">Password</Label>
                          <Field
                            name="password"
                            type="password"
                            placeholder="Enter your password"
                            style={{ height: "40px" }}
                            className={`form-control${
                              errors.password && touched.password
                                ? " is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="confirmPassword">
                            Confirm Password
                          </Label>
                          <Field
                            name="confirmPassword"
                            placeholder="Confirm your password"
                            type="password"
                            style={{ height: "40px" }}
                            className={`form-control${
                              errors.confirmPassword && touched.confirmPassword
                                ? " is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                        <FormGroup inline className="form-check">
                          <Field
                            type="checkbox"
                            name="acceptTerms"
                            id="acceptTerms"
                            className={`form-check-input ${
                              errors.acceptTerms && touched.acceptTerms
                                ? " is-invalid"
                                : ""
                            }`}
                          />
                          <Label htmlFor="acceptTerms">
                            Accept Terms & Conditions
                          </Label>
                          <ErrorMessage
                            name="acceptTerms"
                            component="div"
                            className="invalid-feedback"
                          />
                        </FormGroup>
                        <small className="pb-3 d-block">
                          Already have an account?{" "}
                          <Link to="/auth/login">Login</Link>
                        </small>
                        <FormGroup>
                          <Button
                            type="submit"
                            style={{ backgroundColor: "black" }}
                            className="w-100 py-2 text-light"
                          >
                            Register
                          </Button>
                        </FormGroup>
                      </Form>
                    )}
                  </Formik>
                </div>
                <div className="d-flex align-items-center px-5">
                  <hr className="flex-grow-1 bg-secondary" />
                  <span className="mx-3 text-dark">or</span>
                  <hr className="flex-grow-1 bg-secondary" />
                </div>

                <div className="mt-2 d-flex gap-2">
                  <Button
                    style={{ backgroundColor: "#db4437" }}
                    className="w-100 py-2 text-light"
                    onClick={() =>
                      window.location.href = `${process.env.REACT_APP_FOS_CLIENT_API}/auth/google`
                    }
                  >
                    <i className="fab fa-google me-2"></i> Sign in with Google
                  </Button>
                  <Button
                    style={{ backgroundColor: "#3b5998" }}
                    className="w-100 py-2 text-light"
                    onClick={() => {
                      // Redirect to your NestJS Facebook authentication endpoint
                      window.location.href = `${process.env.REACT_APP_FOS_CLIENT_API}/auth/facebook`;
                    }}
                  >
                    <i className="fab fa-facebook-f me-2"></i> Sign in with
                    Facebook
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default RegisterDemo;
